import {
  loadGoogleAnalytics,
  loadGoogleAnalyticsStub,
} from './load-google-analytics'

export function load() {
  if (process.env.GA_ENV) {
    return loadGoogleAnalytics()
  } else {
    return loadGoogleAnalyticsStub()
  }
}
